import React from "react";
import Footer from "./Footer";
import "./PrivacyPolicy.css"

const Refund = () => {
  return (
    <div className="privacy-policy-main-div">
      <div className='privacy-policy-white-div'>
        <h1>Cancellation & Refund Policy</h1>
        <p>
          At Tend to Mend, we aim to provide exceptional counseling services
          that meet your needs. However, we understand that there may be
          circumstances requiring a refund. This policy outlines the terms and
          conditions for refunds.
        </p>
        <ol>
          <li>
            <h2>Refund Eligibility</h2>
            <p>Refunds are considered under the following conditions:</p>
            <ul>
              <li>
                <strong>Appointment Cancellations:</strong>
                <ul>
                  <li>
                    Full refund for cancellations made at least 24 hours before
                    the scheduled session.
                  </li>
                  <li>
                    No refunds for cancellations made less than 24 hours before
                    the session or for missed appointments.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Technical Issues:</strong>
                <ul>
                  <li>
                    If a session is disrupted due to technical issues on our
                    end, you may reschedule or request a refund.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <h2>Refund Process</h2>
            <ul>
              <li>
                Refund requests must be made within 2 days of the canceled or
                disrupted session.
              </li>
              <li>
                To request a refund, contact us at{" "}
                <a href="mailto:drlalitharagul@tendtomend.com">
                  drlalitharagul@tendtomend.com
                </a>{" "}
                or use the{" "}
                <a href="https://tendtomend.com/contact">contact form</a> with
                the session details and reason for the refund.
              </li>
            </ul>
          </li>
          <li>
            <h2>Non-Refundable Cases</h2>
            <ul>
              <li>Refunds will not be issued for completed sessions.</li>
              <li>
                Refunds are not applicable for dissatisfaction with the outcome
                of the counseling sessions, as results vary based on individual
                circumstances and participation.
              </li>
            </ul>
          </li>
          <li>
            <h2>Refund Method</h2>
            <p>
              Refunds will be processed using the original payment method.
              Please allow 5-10 business days for the refund to reflect in your
              account.
            </p>
          </li>
          <li>
            <h2>Policy Updates</h2>
            <p>
              Tend to Mend reserves the right to update this refund policy at
              any time. Changes will be communicated via our website.
            </p>
          </li>
        </ol>
        <p>
          If you have any questions about our refund policy, feel free to
          contact us:
        </p>
        <ul>
          <li>
            Email:{" "}
            <a href="mailto:drlalitharagul@tendtomend.com">
              drlalitharagul@tendtomend.com
            </a>
          </li>
          <li>
            Website: <a href="https://tendtomend.com">https://tendtomend.com</a>
          </li>
          <li>Phone: +91 9003681043</li>
        </ul>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Refund;
