import React, { useState } from "react";
import "./UserForm.css";
import BannerImage from "./tmimages/Inner-bg.jpg";
import lady1 from "./tmimages/Lady-1.png";
import logo from "./tmimages/gold-logo.png";
import { LuPhoneCall } from "react-icons/lu";
import { IoMdMail } from "react-icons/io";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { TiSocialGooglePlus } from "react-icons/ti";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaPinterestP } from "react-icons/fa";
import { IoShareSocialSharp } from "react-icons/io5";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";

const UserForm = ({ apiBaseUrl,formDataFromUser }) => {
  const [mobileVal, setMobileVal] = useState(true);
  const captchaNav = useNavigate()
  const navToHome = useNavigate()

  const [formData, setFormData] = useState({
    name: "",
    parent: "",
    email: "",
    mobile: "",
    address: "",
    checkedItems: [],
  });

  // Handle input changes and bind them to the formData state
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };



  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    setFormData((prevFormData) => {
      const updatedCheckedItems = checked
        ? [...prevFormData.checkedItems, name] // Add to checkedItems if checked
        : prevFormData.checkedItems.filter((item) => item !== name); // Remove from checkedItems if unchecked

      return {
        ...prevFormData,
        checkedItems: updatedCheckedItems,
      };
    });
  };

  // Handle form submission and send POST request
  const handleSubmit = async (e) => {
    e.preventDefault();
    formDataFromUser(formData)


    try {
      const response = await axios.post(
        `${apiBaseUrl}TSIT_TtoM_Booking`,
        formData
      );
    
      if (response.data.success === true) {
        // window.alert("You have booked successfully");

        setFormData({
          name: "",
          parent: "",
          email: "",
          mobile: "",
          address: "",
          checkedItems: [], // Clear checked items as well
        });

        captchaNav('/captcha')
      }
    } catch (error) {
      if (error.response && error.response.data) {
        // Extracting the error message from the response
        const errorMessage =
          error.response.data.error?.checkedItems?.[0] ||
          "An unexpected error occurred.";
        window.alert(errorMessage);
      } else {
        // Handle other types of errors (e.g., network errors)
        window.alert("An error occurred. Please try again.");
      }
    }
  };
 
  return (
    <div>
      <div>
        <div className="consentform-main-img-div ">
          <img
            className="consentform-main-img"
            src={BannerImage}
            alt="BannerImage"
          />
        </div>
        <div className="consentform-main-logo-div">
          <img className="homepage-logo" onClick={()=>navToHome("/")} src={logo}></img>
        </div>

        <p className="mb-0 consent-tend-text">TEND TO MEND</p>
        <p className="consent-main-counselling-text">COUNSELLING</p>

        <p className="consentform-main-lalitha-text">Dr. Lalitha Ragul,</p>
        <p className="consentform-main-counselling-text">
          Counselling Psychologist,
        </p>
        <p className="consentform-main-marital-text">
        Specialist in Marital, Family & Child Counselling.
        </p>
        <div className="consentform-phone-email-div">
          <span className="consentform-mobile-span me-4">
            <LuPhoneCall className="consent-main-phone-icon " />
            9003681043
          </span>
          <span className="consentform-mail-span">
            <IoMdMail className="consent-main-phone-icon " />
            lalitharagul88@gmail.com
          </span>
        </div>
        <p className="consentform-insta-para">
          <FaInstagram className="consent-main-phone-icon " />
          @tendtomendcouples
        </p>
        <img className="consentform-lady-img" src={lady1}></img>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="userform-affectic-div">
          <p className="userform-affecting-span">
            Please check the items that you believe are affecting you:
          </p>
          <div className="d-flex justify-content-center">
            <div className="userform-affecting-main-div">
              <div className="row">
                <div className="col">
                  <div className="d-flex flex-column gap-3">
                    <div className="userform-affect-field-div">
                      <input
                        className="userform-input-checkbox"
                        type="checkbox"
                        name="Alcohol or Drug Problems"
                        checked={formData.checkedItems.includes("Alcohol or Drug Problems")}
                        onChange={handleCheckboxChange}
                      />
                      <label className="userform-form-lable">
                        Alcohol or Drug Problems
                      </label>
                    </div>
                    <div className="userform-affect-field-div">
                      <input
                        className="userform-input-checkbox"
                        type="checkbox"
                        name="Anxiety, nervousness, fears"
                        checked={formData.checkedItems.includes("Anxiety, nervousness, fears")}
                        onChange={handleCheckboxChange}
                      />
                      <label className="userform-form-lable">
                        Anxiety, nervousness, fears
                      </label>
                    </div>
                    <div className="userform-affect-field-div">
                      <input
                        className="userform-input-checkbox"
                        type="checkbox"
                        name="Eating or Appetite problems"
                        checked={formData.checkedItems.includes("Eating or Appetite problems")}
                        onChange={handleCheckboxChange}
                      />
                      <label className="userform-form-lable">
                        Eating or Appetite problems
                      </label>
                    </div>
                    <div className="userform-affect-field-div">
                      <input
                        className="userform-input-checkbox"
                        type="checkbox"
                        name="Procrastination"
                        checked={formData.checkedItems.includes("Procrastination")}
                        onChange={handleCheckboxChange}
                      />
                      <label className="userform-form-lable">
                        Procrastination
                      </label>
                    </div>
                    <div className="userform-affect-field-div">
                      <input
                        className="userform-input-checkbox"
                        type="checkbox"
                        name="Relationship / Marital concerns"
                        checked={formData.checkedItems.includes("Relationship / Marital concerns")}
                        onChange={handleCheckboxChange}
                      />
                      <label className="userform-form-lable">
                        Relationship / Marital concerns
                      </label>
                    </div>
                    <div className="userform-affect-field-div">
                      <input
                        className="userform-input-checkbox"
                        type="checkbox"
                        name="Shyness"
                        checked={formData.checkedItems.includes("Shyness")}
                        onChange={handleCheckboxChange}
                      />
                      <label className="userform-form-lable">Shyness</label>
                    </div>
                    <div className="userform-affect-field-div">
                      <input
                        className="userform-input-checkbox"
                        type="checkbox"
                        name="Social Conflicts"
                        checked={formData.checkedItems.includes("Social Conflicts")}
                        onChange={handleCheckboxChange}
                      />
                      <label className="userform-form-lable">
                        Social Conflicts
                      </label>
                    </div>
                    <div className="userform-affect-field-div">
                      <input
                        className="userform-input-checkbox"
                        type="checkbox"
                        name="Stress"
                        checked={formData.checkedItems.includes("Stress")}
                        onChange={handleCheckboxChange}
                      />
                      <label className="userform-form-lable">Stress</label>
                    </div>
                    <div className="userform-affect-field-div">
                      <input
                        className="userform-input-checkbox"
                        type="checkbox"
                        name="Self-Control"
                        checked={formData.checkedItems.includes("Self-Control")}
                        onChange={handleCheckboxChange}
                      />
                      <label className="userform-form-lable">
                        Self-Control
                      </label>
                    </div>
                    <div className="userform-affect-field-div userform-work-or-career-field">
                      <input
                        className="userform-input-checkbox"
                        type="checkbox"
                        name="Work or Career concerns"
                        checked={formData.checkedItems.includes("Work or Career concerns")}
                        onChange={handleCheckboxChange}
                      />
                      <label className="userform-form-lable">
                        Work or Career concerns
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex flex-column gap-3 ">
                    <div className="userform-affect-field-div">
                      <input
                        className="userform-input-checkbox"
                        type="checkbox"
                        name="Anger / Hostile Feelings"
                        checked={formData.checkedItems.includes("Anger / Hostile Feelings")}
                        onChange={handleCheckboxChange}
                      />
                      <label className="userform-form-lable">
                        Anger / Hostile Feelings
                      </label>
                    </div>
                    <div className="userform-affect-field-div">
                      <input
                        className="userform-input-checkbox"
                        type="checkbox"
                        name="Sadness or depression"
                        checked={formData.checkedItems.includes("Sadness or depression")}
                        onChange={handleCheckboxChange}
                      />
                      <label className="userform-form-lable">
                        Sadness or depression
                      </label>
                    </div>
                    <div className="userform-affect-field-div">
                      <input
                        className="userform-input-checkbox"
                        type="checkbox"
                        name="Family issues"
                        checked={formData.checkedItems.includes("Family issues")}
                        onChange={handleCheckboxChange}
                      />
                      <label className="userform-form-lable">
                        Family issues
                      </label>
                    </div>
                    <div className="userform-affect-field-div">
                      <input
                        className="userform-input-checkbox"
                        type="checkbox"
                        name="Physical Distress"
                        checked={formData.checkedItems.includes("Physical Distress")}
                        onChange={handleCheckboxChange}
                      />
                      <label className="userform-form-lable">
                        Physical Distress
                      </label>
                    </div>
                    <div className="userform-affect-field-div">
                      <input
                        className="userform-input-checkbox"
                        type="checkbox"
                        name="Sexual Concerns"
                        checked={formData.checkedItems.includes("Sexual Concerns")}
                        onChange={handleCheckboxChange}
                      />
                      <label className="userform-form-lable">
                        Sexual Concerns
                      </label>
                    </div>
                    <div className="userform-affect-field-div">
                      <input
                        className="userform-input-checkbox"
                        type="checkbox"
                        name="Traumatic Experience"
                        checked={formData.checkedItems.includes("Traumatic Experience")}
                        onChange={handleCheckboxChange}
                      />
                      <label className="userform-form-lable">
                        Traumatic Experience
                      </label>
                    </div>
                    <div className="userform-affect-field-div">
                      <input
                        className="userform-input-checkbox"
                        type="checkbox"
                        name="Suicidal feelings / behaviour"
                        checked={formData.checkedItems.includes("Suicidal feelings / behaviour")}
                        onChange={handleCheckboxChange}
                      />
                      <label className="userform-form-lable">
                        Suicidal feelings / behaviour
                      </label>
                    </div>
                    <div className="userform-affect-field-div">
                      <input
                        className="userform-input-checkbox"
                        type="checkbox"
                        name="Sleep disorder"
                        checked={formData.checkedItems.includes("Sleep disorder")}
                        onChange={handleCheckboxChange}
                      />
                      <label className="userform-form-lable">
                        Sleep disorders
                      </label>
                    </div>
                    <div className="userform-affect-field-div">
                      <input
                        className="userform-input-checkbox"
                        type="checkbox"
                        name="Self - Esteem / Confidence"
                        checked={formData.checkedItems.includes("Self - Esteem / Confidence")}
                        onChange={handleCheckboxChange}
                      />
                      <label className="userform-form-lable">
                        Self - Esteem / Confidence
                      </label>
                    </div>
                    <div className="userform-affect-field-div">
                      <input
                        className="userform-input-checkbox"
                        type="checkbox"
                        name="Pre-Marital Counselling"
                        checked={formData.checkedItems.includes("Pre-Marital Counselling")}
                        onChange={handleCheckboxChange}
                      />
                      <label className="userform-form-lable">
                        Pre-Marital Counselling
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="userform-bottom-users-field-main-div">
          <div className="row">
            <div className="col">
              <div className="d-flex flex-column">
                <label className="userform-bottom-user-field-label">
                  Client Name:
                </label>
                <input
                  className="userform-bottom-user-field-input"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex flex-column">
                <label className="userform-bottom-user-field-label">
                  Parent / Guardian (incase of minor client)
                </label>
                <input
                  className="userform-bottom-user-field-input"
                  type="text"
                  name="parent"
                  value={formData.parent}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="d-flex flex-column">
                <label className="userform-bottom-user-field-label">
                  Email
                </label>
                <input
                  className="userform-bottom-user-field-input"
                  type="email"
                  name="email"
                 
                  value={formData.email}
                  onChange={handleChange}
                  onInvalid={(e) => e.target.setCustomValidity("Please enter a valid email address")}
  onInput={(e) => e.target.setCustomValidity("")} 
                  required
                />
              </div>
            </div>

            <div className="col">
              <div className="d-flex flex-column">
                <label className="userform-bottom-user-field-label">
                  Phone Number
                </label>
                <input
                  className="userform-bottom-user-field-input"
                  type="tel"
                  name="mobile"
                  maxLength={"10"}
                  pattern="\d{10}"
                  value={formData.mobile}
                  onChange={handleChange}
                  onInvalid={(e) => e.target.setCustomValidity("Please enter a valid 10-digit phone number")}
  onInput={(e) => e.target.setCustomValidity("")} 
                  required
                />
              </div>
            </div>
          </div>

          <div className="d-flex flex-column">
            <label className="userform-bottom-user-field-label">Address:</label>
            <textarea
              className="userform-bottom-user-field-textarea"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="userform-submit-button-div">
          <button className=" userform-submit-button" type="submit">
            SUBMIT
          </button>
        </div>
      </form>
     <Footer></Footer>
    </div>
  );
};

export default UserForm;
