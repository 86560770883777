import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { TiSocialGooglePlus } from "react-icons/ti";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaPinterestP } from "react-icons/fa";
import { IoShareSocialSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import "./Footer.css";
const Footer = () => {
  const navigate = useNavigate();
  const navToTerm = useNavigate()
  return (
    <div>
      <div className="d-flex justify-content-center homepage-bottom-social-icons">
        <span className="homepage-bottom-facebook-icon-span">
          <FaFacebookF className="homepage-bottom-facebook-icon" />
        </span>
        <span className="homepage-bottom-twitter-icon-span">
          <FaXTwitter className="homepage-bottom-twitter-icon" />
        </span>
        <span className="homepage-bottom-google-icon-span">
          <TiSocialGooglePlus className="homepage-bottom-google-icon" />
        </span>
        <span className="homepage-bottom-linkedin-icon-span">
          <FaLinkedinIn className="homepage-bottom-linkedin-icon" />
        </span>
        <span className="homepage-bottom-pin-icon-span">
          <FaPinterestP className="homepage-bottom-pin-icon" />
        </span>
        <span className="homepage-bottom-share-icon-span">
          <IoShareSocialSharp className="homepage-bottom-share-icon" />
        </span>
      </div>
      <p className="homepage-bottom-privacy-tex-para">
        <span
          className="footer-privacy"
          onClick={() =>{  window.scrollTo(0, 0);
            navigate("/privacypolicy")
          } }
        >
          Privacy Policy
        </span>{" "}
        |{" "}
        <span
          className="footer-privacy"
          onClick={() => {window.scrollTo(0, 0);
            navToTerm("/terms")}
          }
        >
          Terms & Conditions
        </span>{" "}
        | <span  className="footer-privacy" onClick={()=>{window.scrollTo(0, 0);
            navigate("/refund")
        }}>Cancellation & Refund Policy</span>
        | <span className="footer-privacy" onClick={()=>{window.scrollTo(0, 0);
            navigate("/")
        }} > Home</span>
      </p>
      <div className="row homepage-bottom-copyright-row">
        <div className="col-lg-6 col-md-12">
          <p className="homepage-bottom-copy-rights-para-left">
            {" "}
            &copy;2024 All rights reserved - Tendtomend.
          </p>
        </div>
        <div className="col-lg-6 col-md-12">
          <p className="homepage-bottom-copy-rights-para-right">
            Designed by Tabsquareinfotech.com
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
