import React from 'react'
import "./PrivacyPolicy.css"

import Footer from './Footer';
const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-main-div">
        
        <div className='privacy-policy-white-div'>
    <h1>Privacy Policy</h1>
    <p><strong>Last Updated:</strong> November 22, 2024</p>
    <p>
        This Privacy Policy outlines our policies and procedures regarding the collection, use, and 
        disclosure of your information when you use our Service. It also explains your privacy rights and 
        how the law protects you.
    </p>
    <p>
        We use your personal data to provide and improve our Service. By using the Service, you agree 
        to the collection and use of information in accordance with this Privacy Policy.
    </p>

    <h2>Interpretation and Definitions</h2>
    <h3>Interpretation</h3>
    <p>
        Words with initial capital letters have specific meanings outlined below. These definitions apply 
        regardless of whether they appear in singular or plural forms.
    </p>

    <h3>Definitions</h3>
    <ul>
        <li><strong>Account:</strong> A unique account created for you to access our Service.</li>
        <li><strong>Affiliate:</strong> An entity under common ownership or control with the Company.</li>
        <li><strong>Company:</strong> (referred to as "we," "us," or "our") Tend to Mend.</li>
        <li><strong>Cookies:</strong> Small files placed on your device to track your browsing activity and preferences.</li>
        <li><strong>Country:</strong> Tamil Nadu, India.</li>
        <li><strong>Device:</strong> Any device capable of accessing the Service (e.g., computer, mobile phone, tablet).</li>
        <li><strong>Personal Data:</strong> Information that can identify an individual.</li>
        <li><strong>Service:</strong> Refers to the website and services provided by Tend to Mend.</li>
        <li><strong>Service Provider:</strong> A third party that processes data on behalf of the Company.</li>
        <li><strong>Usage Data:</strong> Automatically collected information, such as IP addresses and browsing activities.</li>
        <li><strong>Website:</strong> Refers to Tend to Mend, accessible at <a href="https://tendtomend.com">https://tendtomend.com</a>.</li>
        <li><strong>You:</strong> Refers to the user accessing the Service or the entity they represent.</li>
    </ul>

    <h2>Collecting and Using Your Personal Data</h2>
    <h3>Types of Data Collected</h3>
    <h4>1. Personal Data:</h4>
    <p>We may collect the following personally identifiable information:</p>
    <ul>
        <li>Email address</li>
        <li>First and last name</li>
        <li>Phone number</li>
        <li>Address, city, state, ZIP/postal code</li>
    </ul>
    <h4>2. Usage Data:</h4>
    <p>Automatically collected information such as:</p>
    <ul>
        <li>IP address, browser type, and version</li>
        <li>Pages visited, time spent, and diagnostic data</li>
    </ul>
    <h4>3. Tracking Technologies and Cookies:</h4>
    <ul>
        <li><strong>Necessary Cookies:</strong> Enable essential functionalities.</li>
        <li><strong>Functionality Cookies:</strong> Remember your preferences.</li>
        <li><strong>Analytics Cookies:</strong> Help us understand how users interact with the Service.</li>
    </ul>

    <h3>Use of Your Personal Data</h3>
    <ul>
        <li>Provide and maintain our Service</li>
        <li>Manage your account and user preferences</li>
        <li>Contact you with updates, offers, or support</li>
        <li>Ensure security and prevent fraud</li>
        <li>Analyze user behavior to improve our offerings</li>
    </ul>

    <h3>Retention and Deletion of Data</h3>
    <p>
        We retain your personal data only as long as necessary to fulfill the purposes outlined in this 
        policy. You may request deletion of your data unless retention is required by law.
    </p>

    <h3>Disclosure of Data</h3>
    <p>Your personal data may be disclosed under the following circumstances:</p>
    <ul>
        <li><strong>Business Transactions:</strong> As part of mergers or acquisitions</li>
        <li><strong>Legal Requirements:</strong> To comply with legal obligations or protect rights</li>
        <li><strong>Service Providers:</strong> To facilitate our Service</li>
    </ul>

    <h2>Children’s Privacy</h2>
    <p>
        Our Service is not intended for individuals under 13 years old. If we become aware of 
        unauthorized data collection from children, we will delete it immediately.
    </p>

    <h2>Links to Other Websites</h2>
    <p>
        We are not responsible for the privacy practices of third-party websites linked to our Service. 
        Please review their privacy policies.
    </p>

    <h2>Changes to This Privacy Policy</h2>
    <p>
        We may update this Privacy Policy periodically. Changes will be posted on this page with a 
        revised “Last Updated” date.
    </p>

    <h2>Contact Us</h2>
    <p>If you have any questions about this Privacy Policy, please contact us:</p>
    <ul>
        <li>Email: <a href="mailto:drlalitharagul@tendtomend.com">drlalitharagul@tendtomend.com</a></li>
        <li>Website: <a href="https://tendtomend.com">https://tendtomend.com</a></li>
        <li>Phone: +91 9003681043</li>
    </ul>
</div>
<Footer></Footer>
        </div>
  )
}

export default PrivacyPolicy