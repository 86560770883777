import React from "react";
import Footer from "./Footer";

const TermsAndCondition = () => {
  return (
    <div className="privacy-policy-main-div">
      <div className='privacy-policy-white-div'>
        <h1>Terms and Conditions</h1>
        <ol>
          <li>
            <h2>Introduction</h2>
            <p>
              Welcome to Tend to Mend, an online psychology counseling service
              provided by Dr. Lalitha Ragul. By using our website and services,
              you agree to these Terms and Conditions. Please read them
              carefully before accessing or using our platform.
            </p>
          </li>
          <li>
            <h2>Services Provided</h2>
            <p>
              Tend to Mend offers online psychology counseling with a focus on
              family dynamics, marital relationships, and personal mental
              well-being. These services are intended for guidance and support
              and are not a substitute for emergency medical or psychiatric
              care.
            </p>
          </li>
          <li>
            <h2>Eligibility</h2>
            <ul>
              <li>
                Users must be at least 18 years old or have parental/guardian
                consent to use our services.
              </li>
              <li>
                Services are designed for individuals, couples, or families
                seeking non-emergency psychological support.
              </li>
            </ul>
          </li>
          <li>
            <h2>User Responsibilities</h2>
            <ul>
              <li>
                Users must provide accurate and complete information during
                counseling sessions.
              </li>
              <li>
                Misleading, false, or offensive content is not permitted during
                interactions.
              </li>
              <li>
                Users are responsible for maintaining the confidentiality of
                their login credentials.
              </li>
            </ul>
          </li>
          <li>
            <h2>Confidentiality</h2>
            <ul>
              <li>
                All counseling sessions are confidential, and personal
                information is protected under our Privacy Policy.
              </li>
              <li>
                Exceptions to confidentiality may occur if there is a risk of
                harm to the client or others or if disclosure is required by
                law.
              </li>
            </ul>
          </li>
          <li>
            <h2>Payment and Refund Policy</h2>
            <ul>
              <li>
                Payments must be made in advance for all sessions as per the
                pricing provided on our website.
              </li>
              <li>
                Refunds for canceled sessions are subject to our cancellation
                policy.
              </li>
              <li>
                Missed appointments without prior notice may not qualify for a
                refund.
              </li>
            </ul>
          </li>
          <li>
            <h2>Cancellation and Rescheduling</h2>
            <ul>
              <li>
                Sessions can be canceled or rescheduled with at least 24 hours’
                notice.
              </li>
              <li>
                Cancellations made within 24 hours of the scheduled session may
                incur a fee or result in forfeiture of payment.
              </li>
            </ul>
          </li>
          <li>
            <h2>Disclaimer of Medical Advice</h2>
            <ul>
              <li>
                Tend to Mend provides psychological guidance but does not
                replace professional medical advice, diagnosis, or treatment.
              </li>
              <li>
                For emergencies or crisis situations, users should contact local
                emergency services immediately.
              </li>
            </ul>
          </li>
          <li>
            <h2>Intellectual Property</h2>
            <p>
              All website content, including text, images, and branding, is the
              property of Tend to Mend. Unauthorized copying, distribution, or
              use of this content is prohibited.
            </p>
          </li>
          <li>
            <h2>Limitation of Liability</h2>
            <p>
              Tend to Mend and Dr. Lalitha Ragul are not liable for any outcomes
              resulting from the use of our services. Users are encouraged to
              make informed decisions based on the guidance provided.
            </p>
          </li>
          <li>
            <h2>Governing Law</h2>
            <p>
              These Terms and Conditions are governed by the laws of [Your
              Jurisdiction]. Any disputes will be resolved in accordance with
              local legal provisions.
            </p>
          </li>
          <li>
            <h2>Modification of Terms</h2>
            <p>
              Tend to Mend reserves the right to amend these Terms and
              Conditions at any time. Changes will be posted on this page, and
              continued use of our services implies acceptance of the revised
              terms.
            </p>
          </li>
          <li>
            <h2>Contact Information</h2>
            <p>If you have any questions or concerns, please contact us:</p>
            <ul>
              <li>
                Email:{" "}
                <a href="mailto:drlalitharagul@tendtomend.com">
                  drlalitharagul@tendtomend.com
                </a>
              </li>
              <li>
                Website:{" "}
                <a href="https://tendtomend.com">https://tendtomend.com</a>
              </li>
              <li>Phone: +91 9003681043</li>
            </ul>
          </li>
        </ol>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default TermsAndCondition;
