import React, { useState } from "react";
import "./FeePage.css";
import BannerImage from "./tmimages/Inner-bg.jpg";
import lady1 from "./tmimages/Lady-1.png";
import logo from "./tmimages/gold-logo.png";
import { FaInstagram } from "react-icons/fa";
import { LuPhoneCall } from "react-icons/lu";
import { IoMdMail } from "react-icons/io";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { TiSocialGooglePlus } from "react-icons/ti";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaPinterestP } from "react-icons/fa";
import { IoShareSocialSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
const FeePage = () => {

    const [checkBox,setCheckBox]= useState(false)
    const navigate = useNavigate()
    const navToHome = useNavigate()

    const handleNext = ()=>{
        if(checkBox){
          window.scrollTo(0, 0);
            navigate("/userform")
          
        } else{
            window.alert("please accept the agreement checkbox")
        }
    }
  return (
    <div >
      {" "}
      <div>
        <div className="consentform-main-img-div ">
          <img
            className="consentform-main-img"
            src={BannerImage}
            alt="BannerImage"
          />
        </div>
        <div className="consentform-main-logo-div">
          <img className="homepage-logo" onClick={()=>navToHome("/")}src={logo}></img>
        </div>

        <p className="mb-0 consent-tend-text">TEND TO MEND</p>
        <p className="consent-main-counselling-text">COUNSELLING</p>

        <p className="consentform-main-lalitha-text">Dr. Lalitha Ragul,</p>
        <p className="consentform-main-counselling-text">
          Counselling Psychologist,
        </p>
        <p className="consentform-main-marital-text">
          Marital, Family & Child Counselling Specialist,
        </p>
        <div className="consentform-phone-email-div">
          <span className="consentform-mobile-span me-2">
            <LuPhoneCall className="consent-main-phone-icon " />
            9003681043
          </span>
          <span className="consentform-mail-span">
            <IoMdMail className="consent-main-phone-icon " />
            lalitharagul88@gmail.com
          </span>
        </div>
        <p className="consentform-insta-para">
          <FaInstagram className="consent-main-phone-icon " />
          @tendtomendcouples
        </p>
        <img className="consentform-lady-img" src={lady1}></img>
      </div>
      <div className="d-flex justify-content-center">
        <div className="feepage-main-content-div">
          <p className="feepage-fee-agree-text">Fee Agreement Form</p>
          <p className="feepage-counsellor-text">
            Counsellor Responsibilities:
          </p>
          <div className="feepage-1st-ul-div">
            <ul className="feepage-1st-ul">
              <li>To be available at the agreed time</li>
              <li>To start and end on time</li>
              <li>To offer a quiet appropriate and undisturbed space</li>
              <li>To maintain safe, professional boundaries</li>
              <li>
                To regard all contact and information as confidential unless he/
                she has reasonable doubt concerning actual safety of the client
                or others
              </li>
              <li>To encourage client autonomy</li>
              <li>
                {" "}
                In the unlikely event of the therapist cancelling, an
                alternative appointment offered ASAP
              </li>
            </ul>
          </div>

          <p className="feepage-counsellor-text mt-4">
            Client Responsibilities
          </p>
          <div className="feepage-1st-ul-div">
            <ul className="feepage-1st-ul">
              <li>To attend punctually</li>
              <li>
                To give a minimum of 48 hours’ notice when cancelling/changing
                an appointment (or the full fee becomes payable)
              </li>
              <li>
                Communicating with the therapist outside agreed counselling
                sessions to be limited to making, changing or cancelling an
                appointment unless by prior arrangement.
              </li>
              <li>
                To agree to give permission to contact GP if the therapist has
                serious concerns about risk to self (client) or others.
              </li>
              <li>
                To discuss with the therapist when you feel you are ready to end
                therapy.
              </li>
              <li>
                To let the therapist know if you are in or are considering
                entering another therapeutic relationship.
              </li>
            </ul>
          </div>
          <p className="feepage-bottom-paras mt-4">
            The fee for the initial session would be{" "}
            <span className="feepage-bottom-para-span">INR 1200/-</span> for a{" "}
            <span className="feepage-bottom-para-span">50 minutes</span> to{" "}
            <span className="feepage-bottom-para-span">1 hour </span>
              counselling session. The couple sessions are priced for{" "}
            <span className="feepage-bottom-para-span">1500/- per hour.</span>
          </p>
          <p className="feepage-bottom-paras">
            This means you would pay the fee at the time of the appointment. In
            acceptance of the contract and payment, appointment for the sessions
            would be confirmed.
          </p>
          <div className="d-flex align-items-center feepage-bottom-input-label-checkbox">
            <input className="feepage-bottom-input-checkbox" value={checkBox} onClick={()=>setCheckBox(!checkBox)} type="checkbox"></input>
            <label className="feepage-bottom-paras">I accept this agreement and consent to counselling</label>

          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end feepage-next-button-div">
        <button className="consentform-next-button" onClick={handleNext}>NEXT</button>
      </div>
     <Footer></Footer>
    </div>
  );
};

export default FeePage;
