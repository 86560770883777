import React, { useState } from "react";
import "./ConsentForm.css";
import BannerImage from "./tmimages/Inner-bg.jpg";
import lady1 from "./tmimages/Lady-1.png";
import logo from "./tmimages/gold-logo.png";
import sunimage from "./tmimages/sun-image1.png";
import { LuPhoneCall } from "react-icons/lu";
import { IoMdMail } from "react-icons/io";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { TiSocialGooglePlus } from "react-icons/ti";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaPinterestP } from "react-icons/fa";
import { IoShareSocialSharp } from "react-icons/io5";
import { BiCheck } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";

const ConsentForm = () => {

const [condition,setCondition] = useState(false)
const navToHome = useNavigate()

const navFee = useNavigate()
const handleNextPage = ()=>{
  if (condition){
    window.scrollTo(0, 0);
navFee("/aboutfee");

  }else{
    alert("Please accept the agreement checkbox")
  }
}

  return (
    <div>
      {" "}
      <div>
        <div className="consentform-main-img-div ">
          <img
            className="consentform-main-img"
            src={BannerImage}
            alt="BannerImage"
          />
        </div>
        <div className="consentform-main-logo-div">
          <img className="homepage-logo" src={logo} onClick={()=>navToHome("/")}></img>
        </div>

        <p className="mb-0 consent-tend-text">TEND TO MEND</p>
        <p className="consent-main-counselling-text">COUNSELLING</p>

        <p className="consentform-main-lalitha-text">Dr. Lalitha Ragul,</p>
        <p className="consentform-main-counselling-text">
          Counselling Psychologist,
        </p>
        <p className="consentform-main-marital-text">
        Specialist in Marital, Family & Child Counselling.
        </p>
        <div className="consentform-phone-email-div">
          <span className="consentform-mobile-span me-4">
            <LuPhoneCall className="consent-main-phone-icon " />
            9003681043
          </span>
          <span className="consentform-mail-span">
            <IoMdMail className="consent-main-phone-icon " />
            lalitharagul88@gmail.com
          </span>
        </div>
        <p className="consentform-insta-para">
          <FaInstagram className="consent-main-phone-icon " />
          @tendtomendcouples
        </p>
        <img className="consentform-lady-img" src={lady1}></img>
      </div>
      <div className="row">
        <div className="col-lg-9 d-flex justify-content-center">
          <div className="consent-form-informed-consent-div">
            <p className="mb-0 consent-form-informed-consent-text">
              Informed Consent Form
            </p>
            <p className="consent-form-iam-text">
              I am, Lalitha Ragul, counselling psychologist specializing in
              Marital, Family and Child counselling with years of experience in
              various counselling techniques. I value my relationship with the
              clients and believe that such relationship is the beacon in the
              healing process.
            </p>
            <p className="consent-form-iam-text">
              I believe that each individual is unique and has his/her own way
              of addressing resolutions. Thus, I believe in a wellness model
              that helps clients to empower themselves by focusing on what works
              for them and not in a systematic approach that provides a generic
              procedure on working on a treatment. One’s journey is not same as
              the other.
            </p>

            <img className="consentform-sunhand-img" src={sunimage}></img>
          </div>
        </div>
      </div>

      <div className="row">
<div className="col d-flex justify-content-center">
    <div className="consentform-clients-rights-main-div">
        <p className="consent-form-informed-consent-text">Clients Right's</p>
        <div className="d-flex flex-column consentform-clients-points-div gap-3">
           <span>1. The client may ask questions on what to expect during and end result of the therapy.</span>
           <span>2. The client may decline to proceed the therapy as to the techniques which may be
           conducted by the therapist.</span>
           <span>
           3. The client may cease to continue therapy anytime, without any impediment and may
           return to therapy anytime.
           </span>
           <sapn>
           4. The therapist has the right to dismiss the client from the course of therapy.
           </sapn>
           <span>
           5. The client has the right to review his or her records from the therapist.
           </span>
           <span>
           6. Right to confidentiality: Within limits provided for by law, all records and information
acquired by the therapist shall be kept strictly confidential in accordance to the
principles of a doctor-patient relationship. All information will not be shared or
revealed to any person, agency, or organization without the prior written consent of
the client.
           </span>
           <span>
           7. The client can raise any concerns and to speak with the therapist immediately of any
concerns provided that the therapist is likewise available to discuss matters with the
client.
           </span>
        </div>
    </div>
</div>

      </div>
      <div className="d-flex justify-content-center">
      <div className="consentform-acknowledge-div" >
        <p className="consent-form-informed-consent-text mt-2 ps-1 mb-0"> Acknowledgement</p>
        <p className="consentform-acknowledge-para">I have reviewed this professional counselling Informed Consent Agreement. I likewise
        understand my rights set under “Client’s Rights” in this form.</p>
      </div>
      
      </div>
      <div className="d-flex justify-content-center">
        <div className="consentform-acknowledge-checkbox">
        <input className="consentform-bottom-checkbox" value={condition} onClick={()=>setCondition(!condition)} type="checkbox" id="acknowledgement" name="acknowledgement" ></input>
        <label for="acknowledgement" className="consentform-i-accept-this-text">I accept this agreement and consent to counselling.</label>
        
        </div>
      </div>
      <div className="d-flex justify-content-end consentform-button-div">
        <button className="consentform-next-button" onClick={handleNextPage}>NEXT</button>
      </div>
   
<Footer></Footer>
    </div>
  );
};

export default ConsentForm;
